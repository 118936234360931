body {
  margin: 0;
  padding: 0;
  font-family: Calibre,Helvetica,Arial,sans-serif;
  background-color: #dfe0fa;
}

td {
  border: 1px solid #eee;
  padding: 4px 8px;
}
.title {
  text-align: center;
  margin: 0px;
}
.made-by {
  text-align: center;
  font-size: 0.9rem;
}
.container {
  width: 1024px;
  margin: 0 auto;
}

.sales-container {
  text-align: left;
  padding: 10px;
}

.sales-container table {
  margin-top: 10px;
  margin-left: -2px;
}

.sales-container li {
  width: 33%;
  float: left;
  display: inline-block;
}

.ps {
  font-size: 0.7rem;
}

.sales-container .ReactTable {
  background-color: #fff;
  color: #444;
  border-radius: 5px;
  border: 1px solid #cfbef7;
}
.totals-info {
  float: left;
}
.stats-info {
  float: right;
  text-align: right;
}

.clear {
  clear: both;
}
